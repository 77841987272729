import React, {
    Component
} from 'react'
import {
    connect
} from 'react-redux'
import PropTypes from 'prop-types'
import {
    Button,
    OverlayTrigger,
    Tooltip
} from 'react-bootstrap'
import FontAwesomeIcon from '../FontAwesomeIcon/FontAwesomeIcon'
import {
    trans
} from '../../utils/translations'
import {
    removeItem
} from '../XlsTable/actions/items'

const removeToolTip = (
    <Tooltip id="tooltip">
      {trans.removeRow}
    </Tooltip>
)

class XlsTableRemoveRowButton extends Component {

    constructor(props) {
        super(props)

        this.handleRemoveRow = this.handleRemoveRow.bind(this)
    }

    handleRemoveRow(e) {
        if (confirm(trans.areYouSureYouWantToRemoveRow)) {
            this.props.dispatch(removeItem(this.props.item.id))
        }
    }

    render() {
        return (
            <OverlayTrigger placement="top" overlay={removeToolTip}>
                <Button onClick={this.handleRemoveRow} bsSize="xsmall" className="table-row-btn">
                    <FontAwesomeIcon icon="trash"/>
                </Button>
            </OverlayTrigger>
        )
    }
}

XlsTableRemoveRowButton.propTypes = {
    item: PropTypes.object.isRequired
}

export default connect()(XlsTableRemoveRowButton);