import reducers from "./reducers";
import reduxThunk from 'redux-thunk';
import {
    createStore,
    applyMiddleware
} from 'redux';
import {
    composeWithDevTools
} from 'redux-devtools-extension';

if (process.env.NODE_ENV != 'production') {
    var store = createStore(
        reducers,
        composeWithDevTools(
            applyMiddleware(reduxThunk)
        )
    )
} else {
    const createStoreWithMiddleware = applyMiddleware(reduxThunk)(createStore)
    var store = createStoreWithMiddleware(reducers)
}

export default store;