import {
    LANGUAGES_REQUESTED,
    LANGUAGES_RECEIVED,
    LANGUAGES_FAILED,
    LANGUAGES_RESET_RESULTS,
    LANGUAGES_CHANGE
} from '../actions/types';
import {
    langId
} from '../../../utils/translations';
import {
    currentLang,
    changeLang
} from '../utils/languages';

const initialState = {
    fetching: false,
    items: [],
    error: {},
    lang: currentLang() ? currentLang() : changeLang(langId)
}

export const languages = (state = initialState, action) => {
    switch (action.type) {
        case LANGUAGES_REQUESTED:
            return { ...state,
                fetching: true,
                error: {}
            }
        case LANGUAGES_RECEIVED:
            return { ...state,
                fetching: false,
                error: {},
                items: action.payload
            }
        case LANGUAGES_FAILED:
            return { ...state,
                fetching: false,
                error: action.error
            }
        case LANGUAGES_RESET_RESULTS:
            return { ...state,
                fetching: false,
                items: [],
                error: {}
            }
        case LANGUAGES_CHANGE:
            return { ...state,
                lang: changeLang(action.id)
            }
    }

    return state
}