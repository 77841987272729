import {
    NOT_FOUND_CLICKED,
    NOT_FOUND_RESET
} from '../actions/types';

const initialState = {
    clicked: false
}

export const notFoundClicked = (state = initialState, action) => {
    switch (action.type) {
        case NOT_FOUND_CLICKED:
            return { ...state,
                clicked: true
            }
        case NOT_FOUND_RESET:
            return { ...state,
                clicked: false
            }
    }
    
    return state;
}