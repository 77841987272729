import React, { Component } from 'react';
import FontAwesomeIcon from '../FontAwesomeIcon/FontAwesomeIcon';
import { trans } from '../../utils/translations';

class Loading extends Component {
    render() {
        return (
            <div>
                <FontAwesomeIcon icon="spinner" spin/> {trans.loading}...
            </div>
        )
    }
}

export default Loading;