export const API_URL = 'https://www.nordic-digital.com/module/ndoxls/api';
export const API_KEY = '368097d22833583d43d89aa1b0da6d7b56e832e7';
export const SECURE_API_URL = API_URL  + '?api_key=' + API_KEY
export const ITEMS_URL = SECURE_API_URL + '&action=get_products'; 
export const COLUMNS_URL = SECURE_API_URL + '&action=get_columns';
export const SELECT_COLUMNS_URL = SECURE_API_URL + '&action=get_select_columns';
export const EXCEL_URL = SECURE_API_URL + '&action=get_excel';
export const LANGUAGES_URL = SECURE_API_URL + '&action=get_languages';
export const ADD_MULTIPLE_ITEMS_URL = SECURE_API_URL + '&action=add_multiple_items';
export const SAVE_TEMPLATE_URL = SECURE_API_URL + '&action=add_template';
export const SAVE_EXCEL_URL = SECURE_API_URL + '&action=save_excel';
export const MY_TEMPLATES_URL =  SECURE_API_URL + '&action=my_templates';
export const DELETE_MY_TEMPLATE_URL = SECURE_API_URL + '&action=delete_my_template';
export const MY_EXCELS_URL =  SECURE_API_URL + '&action=my_excels';
export const ITEMS_BY_ID_URL = SECURE_API_URL + '&action=add_products_by_ids';
