import {
    combineReducers
} from 'redux';
import {
    reducer as toastrReducer
} from 'react-redux-toastr';

import xlstableReducer from './components/XlsTable/reducers';
import myTemplatesReducer from './components/XlsTableMyTemplates/reducers';
import myExcelsReducer from './components/XlsTableMyExcels/reducers';

const rootReducer = combineReducers({
    items: xlstableReducer.items,
    columns: xlstableReducer.columns,
    selectColumns: xlstableReducer.selectColumns,
    excel: xlstableReducer.excel,
    languages: xlstableReducer.languages,
    notFoundClicked: xlstableReducer.notFoundClicked,
    toastr: toastrReducer,
    myTemplates: myTemplatesReducer.myTemplates,
    modal: xlstableReducer.modal,
    myExcels: myExcelsReducer.myExcels,
})

export default rootReducer