import {
    SELECT_COLUMNS_REQUESTED,
    SELECT_COLUMNS_RECEIVED,
    SELECT_COLUMNS_FAILED,
    SELECT_COLUMNS_RESET_RESULTS,
    SELECT_COLUMNS_REMOVE_USED
} from '../actions/types';
import { removeUsedColumns } from '../utils/columns';

const initialState = {
    fetching: false,
    items: [],
    error: {}
}

export const selectColumns = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_COLUMNS_REQUESTED:
            return { ...state,
                fetching: true,
                error: {}
            }
        case SELECT_COLUMNS_RECEIVED:
            return { ...state,
                fetching: false,
                error: {},
                items: removeUsedColumns(action.payload, action.columns)
            }
        case SELECT_COLUMNS_FAILED:
            return { ...state,
                fetching: false,
                error: action.error
            }
        case SELECT_COLUMNS_RESET_RESULTS:
            return { ...state,
                fetching: false,
                items: [],
                error: {}
            }
        case SELECT_COLUMNS_REMOVE_USED:
            return { ...state,
                fetching: false,
                items: removeUsedColumns(state.items, action.columns),
                error: {}
        }
    }

    return state;
}