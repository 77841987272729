import axios from 'axios';
import {
    LANGUAGES_URL
} from '../../../../../config/config';
import {
    LANGUAGES_REQUESTED,
    LANGUAGES_RECEIVED,
    LANGUAGES_FAILED,
    LANGUAGES_RESET_RESULTS,
    LANGUAGES_CHANGE
} from './types';
import {
    errorMsg
} from '../../../utils/error';

const failed = (error) => {
    errorMsg(error);
    return {
        type: LANGUAGES_FAILED,
        error
    }
}

const requested = () => {
    return {
        type: LANGUAGES_REQUESTED
    }
}

const received = (payload) => {
    return {
        type: LANGUAGES_RECEIVED,
        payload
    }
}

export const fetchLanguages = () => {
    return (dispatch) => {
        dispatch(requested());
        axios.get(LANGUAGES_URL)
            .then((resp) => {
                const { data } = resp;
                if (data.success) {
                    dispatch(received(data.items));
                } else {
                    dispatch(failed(data.errors));
                }
            })
            .catch((error) => {
                dispatch(failed(error.response.data.errors));
            });
    }
}

export const resetSelectColumns = () => {
    return {
        type: LANGUAGES_RESET_RESULTS
    }
}

export const changeLanguage = (id) => {
    return {
        type: LANGUAGES_CHANGE,
        id
    }
}
