import React, {
    Component
} from 'react';
import PropTypes from 'prop-types';

class FontAwesomeIcon extends Component {
    render() {
        const {
            icon,
            spin
        } = this.props

        let classes = 'fa fa-' + icon

        if (spin) {
            classes += ' fa-spin'
        }

        return (
            <i className={classes}></i>
        )
    }
}

FontAwesomeIcon.propTypes = {
    icon: PropTypes.string.isRequired,
    spin: PropTypes.bool
}

export default FontAwesomeIcon;