export const calcDiscountPrice = (percent, price) =>  {
    price = commaToDot(price)
    let newPrice  = price - (price * (percent / 100)) 
    return dotToComma(round(newPrice))
}

export const calcMarginPerc = (price, netPrice) =>  {
    price = commaToDot(price)
    netPrice = commaToDot(netPrice)
    let newPrice  = (price - netPrice)  / netPrice * 100 
    return round(newPrice)
}

export const calcPriceDiscountPerc = (oldPrice, newPrice) => {
    oldPrice = commaToDot(oldPrice)
    newPrice = commaToDot(newPrice)
    let resp = ((oldPrice - newPrice) * 100) / oldPrice
    return resp.toFixed(0)
}

export const calcClientMarkup = (rrp, discount, tax) => {
    rrp = commaToDot(rrp)
    discount = commaToDot(discount)
    const rrpWithoutTax = rrp / tax
    const percent = ((rrpWithoutTax / discount) - 1) * 100
    return percent.toFixed(2)
}

export const commaToDot = (input) => {
    input = '' + input
    return input.replace(/,/g, '.')
}

export const dotToComma = (input) => {
    input = '' + input
    return input.replace(/\./g, ',')
}

export const round = (num) => {
    return Math.round( ( num + Number.EPSILON ) * 100 ) / 100
}