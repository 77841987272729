import React, {
    PureComponent
} from 'react';
import {
    connect
} from 'react-redux';
import {
    OverlayTrigger
} from 'react-bootstrap';
import './scss/style.scss';
import {
    displayTooltip,
    countItems
} from '../XlsTable/utils/items';
import {
    trans
} from '../../utils/translations';
import {
    ndoData
} from '../../utils/ndo';
import FontAwesomeIcon from '../FontAwesomeIcon/FontAwesomeIcon';

class Cart extends PureComponent {
    render() {
        const {
            items 
        } = this.props

        return (
            <OverlayTrigger placement="bottom" overlay={displayTooltip(trans.goToExcelGeneration)}>
                <a href={ndoData.baseUrl} className="btn btn-default cart-btn">
                    <FontAwesomeIcon icon="file-excel-o"/> {countItems(items)}
                </a>
            </OverlayTrigger>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        items: state.items.items
    }
}

export default connect(
    mapStateToProps,
    null
)(Cart);