import {
  ITEMS_REQUESTED,
  ITEMS_RECEIVED,
  ITEMS_FAILED,
  ITEMS_RESET_RESULTS,
  ITEMS_ADD_TO_START,
  ITEMS_ADD_TO_END,
  ITEMS_ADD_BUTTONS,
  ITEMS_REMOVE,
  ITEMS_REMOVE_BY_ROW_TYPE,
  ITEMS_SIGNLE_EDIT,
  ADD_MULTIPLE_ITEMS_REQUESTED,
  ADD_MULTIPLE_ITEMS_RECEIVED,
  ADD_MULTIPLE_ITEMS_FAILED,
  ITEMS_REPLACE_HEADER_FOOTER,
  ITEMS_BY_URL_REQUESTED,
  ITEMS_BY_URL_RECEIVED,
  ITEMS_BY_URL_FAILED,
  ITEMS_PLACE,
  ITEMS_SORT,
  ITEMS_MULTIPLE_EDIT,
} from '../actions/types';
import {
  addButtons,
  removeItem,
  removeItemsByRowType,
  replaceItemValue,
  cacheItems,
  getCachedItems,
  getItems,
  addMissingItems,
  replaceHeaderFooter,
  placeByIndex,
  sortByColumn,
  replaceItemsValues
} from '../utils/items';
import {
  getFromCache,
  checkFromCache
} from '../utils/cache';

const initialState = {
  fetching: false,
  items: addButtons(getCachedItems()),
  missingItems: getFromCache('missingItems'),
  error: {}
}

export const items = (state = initialState, action) => {
  switch (action.type) {
    case ITEMS_REQUESTED:
      return {
        ...state,
        fetching: true,
        error: {}
      }
    case ITEMS_RECEIVED:
      return {
        ...state,
        fetching: false,
        error: {},
        items: getItems(state, action)
      }
    case ITEMS_ADD_TO_START:
      return {
        ...state,
        fetching: false,
        error: {},
        items: addButtons(cacheItems([action.item, ...state.items]))
      }
    case ITEMS_ADD_TO_END:
      return {
        ...state,
        fetching: false,
        error: {},
        items: addButtons(cacheItems([...state.items, action.item]))
      }
    case ITEMS_FAILED:
      return {
        ...state,
        fetching: false,
        error: action.error
      }
    case ITEMS_RESET_RESULTS:
      return {
        ...state,
        fetching: false,
        items: [],
        error: {}
      }
    case ITEMS_ADD_BUTTONS:
      return {
        ...state,
        fetching: false,
        error: {},
        items: addButtons(state.items)
      }
    case ITEMS_REMOVE:
      return {
        ...state,
        fetching: false,
        error: {},
        items: addButtons(cacheItems(removeItem(state.items, action.item)))
      }
    case ITEMS_REMOVE_BY_ROW_TYPE:
      return {
        ...state,
        fetching: false,
        error: {},
        items: addButtons(cacheItems(removeItemsByRowType(state.items, action.rowType))),
        missingItems: checkFromCache([], 'missingItems')
      }
    case ITEMS_SIGNLE_EDIT:
      return {
        ...state,
        fetching: false,
        error: {},
        items: addButtons(cacheItems(replaceItemValue(state.items, action.value, action.id, action.key)))
      }
    case ITEMS_MULTIPLE_EDIT:
      return {
        ...state,
        fetching: false,
        error: {},
        items: addButtons(cacheItems(replaceItemsValues(state.items, action.values)))
      }
    case ADD_MULTIPLE_ITEMS_REQUESTED:
      return {
        ...state,
        fetching: true,
        error: {}
      }
    case ADD_MULTIPLE_ITEMS_RECEIVED:
      return {
        ...state,
        fetching: false,
        error: {},
        items: getItems(state, action),
        missingItems: addMissingItems(state.missingItems, action.missingItems)
      }
    case ADD_MULTIPLE_ITEMS_FAILED:
      return {
        ...state,
        fetching: false,
        error: action.error
      }
    case ITEMS_REPLACE_HEADER_FOOTER:
      return {
        ...state,
        fetching: false,
        error: {},
        items: replaceHeaderFooter(state, action)
      }
    case ITEMS_BY_URL_REQUESTED:
      return {
        ...state,
        fetching: true,
        error: {}
      }
    case ITEMS_BY_URL_RECEIVED:
      return {
        ...state,
        fetching: false,
        error: {},
        items: getItems(state, action),
        missingItems: addMissingItems(state.missingItems, action.missingItems)
      }
    case ITEMS_BY_URL_FAILED:
      return {
        ...state,
        fetching: false,
        error: action.error
      }
    case ITEMS_PLACE:
      return {
        ...state,
        items: placeByIndex(state, action.oldIndex, action.newIndex)
      }
    case ITEMS_SORT:
      return {
        ...state,
        items: sortByColumn(state, action.column, action.desc)
      }
  }
  return state;
}