export const MODAL_OPENED = 'MODAL_OPENED';
export const MODAL_CLOSED = 'MODAL_CLOSED';
export const ITEMS_REQUESTED = 'ITEMS_REQUESTED';
export const ITEMS_RECEIVED = 'ITEMS_RECEIVED';
export const ITEMS_FAILED = 'ITEMS_FAILED';
export const ITEMS_RESET_RESULTS = 'ITEMS_RESET_RESULTS';
export const ITEMS_ADD_TO_START = 'ITEMS_ADD_TO_START';
export const ITEMS_ADD_TO_END = 'ITEMS_ADD_TO_END';
export const ITEMS_ADD_BUTTONS = 'ITEMS_ADD_BUTTONS';
export const ITEMS_REMOVE = 'ITEMS_REMOVE';
export const ITEMS_REMOVE_BY_ROW_TYPE  = 'ITEMS_REMOVE_BY_ROW_TYPE';
export const ITEMS_SIGNLE_EDIT = 'ITEMS_SIGNLE_EDIT';
export const ITEMS_REPLACE_HEADER_FOOTER = 'ITEMS_REPLACE_HEADER_FOOTER';
export const ITEMS_BY_URL_REQUESTED = 'ITEMS_BY_URL_REQUESTED';
export const ITEMS_BY_URL_RECEIVED = 'ITEMS_BY_URL_RECEIVED';
export const ITEMS_BY_URL_FAILED = 'ITEMS_BY_URL_FAILED';
export const COLUMNS_REQUESTED = 'COLUMNS_REQUESTED';
export const COLUMNS_RECEIVED = 'COLUMNS_RECEIVED';
export const COLUMNS_FAILED = 'COLUMNS_FAILED';
export const COLUMNS_RESET_RESULTS = 'COLUMNS_RESET_RESULTS';
export const COLUMNS_ADD = 'COLUMNS_ADD';
export const COLUMNS_REMOVE = 'COLUMNS_REMOVE';
export const COLUMNS_EDITABLE_CELL_ADD = 'COLUMNS_EDITABLE_CELL_ADD';
export const COLUMNS_ADD_DISPLAY_TYPES = 'COLUMNS_ADD_DISPLAY_TYPES';
export const COLUMNS_REBUILD = 'COLUMNS_REBUILD';
export const SELECT_COLUMNS_REQUESTED = 'SELECT_COLUMNS_REQUESTED';
export const SELECT_COLUMNS_RECEIVED = 'SELECT_COLUMNS_RECEIVED';
export const SELECT_COLUMNS_FAILED = 'SELECT_COLUMNS_FAILED';
export const SELECT_COLUMNS_RESET_RESULTS = 'SELECT_COLUMNS_RESET_RESULTS';
export const SELECT_COLUMNS_REMOVE_USED = 'SELECT_COLUMNS_REMOVE_USED';
export const EXCEL_REQUESTED = 'EXCEL_REQUESTED';
export const EXCEL_RECEIVED = 'EXCEL_RECEIVED';
export const EXCEL_FAILED = 'EXCEL_FAILED';
export const EXCEL_RESET_RESULTS = 'EXCEL_RESET_RESULTS';
export const EXCEL_GENERATE_START = 'EXCEL_GENERATE_START';
export const EXCEL_GENERATE_END = 'EXCEL_GENERATE_END';
export const LANGUAGES_REQUESTED = 'LANGUAGES_REQUESTED';
export const LANGUAGES_RECEIVED = 'LANGUAGES_RECEIVED';
export const LANGUAGES_FAILED = 'LANGUAGES_FAILED';
export const LANGUAGES_RESET_RESULTS = 'LANGUAGES_RESET_RESULTS';
export const LANGUAGES_CHANGE = 'LANGUAGES_CHANGE';
export const ADD_MULTIPLE_ITEMS_REQUESTED = 'ADD_MULTIPLE_ITEMS_REQUESTED';
export const ADD_MULTIPLE_ITEMS_RECEIVED = 'ADD_MULTIPLE_ITEMS_RECEIVED';
export const ADD_MULTIPLE_ITEMS_FAILED = 'ADD_MULTIPLE_ITEMS_FAILED';
export const SAVE_TEMPLATE_REQUESTED = 'SAVE_TEMPLATE_REQUESTED';
export const SAVE_TEMPLATE_RECEIVED = 'SAVE_TEMPLATE_RECEIVED';
export const SAVE_TEMPLATE_FAILED = 'SAVE_TEMPLATE_FAILED';
export const NOT_FOUND_CLICKED = 'NOT_FOUND_CLICKED';
export const NOT_FOUND_RESET = 'NOT_FOUND_RESET';
export const SAVE_EXCEL_REQUESTED = 'SAVE_EXCEL_REQUESTED';
export const SAVE_EXCEL_RECEIVED = 'SAVE_EXCEL_RECEIVED';
export const SAVE_EXCEL_FAILED = 'SAVE_EXCEL_FAILED';
export const ITEMS_PLACE = 'ITEMS_PLACE';
export const ITEMS_SORT = 'ITEMS_SORT';
export const ITEMS_MULTIPLE_EDIT = 'ITEMS_MULTIPLE_EDIT';
export const COLUMNS_CLEAN_HEADER = 'COLUMNS_CLEAN_HEADER';

