const TYPE_NUMBER = 'number';

export const addSortMethods = (columns) => {
  return columns.map((column) => {
    column = addSortMethod(column);
    return column;
  });
}

export const addSortMethod = (column) => {
  if (column.sortType === undefined) {
    return column;
  }

  switch(column.sortType) {
    case TYPE_NUMBER:
      column.sortMethod = (a, b) => sortByNumber(a, b);
    break;
  }

  return column;
}

const sortByNumber = (a, b) => {
  if (a === undefined || b === undefined) {
    return false;
  }
  a = parseFloat(a.replace(',', '.'));
  b = parseFloat(b.replace(',', '.'));
  return Number(a) - Number(b);
}
