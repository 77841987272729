import axios from 'axios';
import {
  ITEMS_URL
} from '../../../../../config/config';
import {
  ITEMS_REQUESTED,
  ITEMS_RECEIVED,
  ITEMS_FAILED,
  ITEMS_RESET_RESULTS,
  ITEMS_ADD_TO_START,
  ITEMS_ADD_TO_END,
  ITEMS_ADD_BUTTONS,
  ITEMS_REMOVE,
  ITEMS_REMOVE_BY_ROW_TYPE,
  ITEMS_SIGNLE_EDIT,
  ITEMS_REPLACE_HEADER_FOOTER,
  ITEMS_PLACE,
  ITEMS_SORT,
  ITEMS_MULTIPLE_EDIT,
} from './types';
import {
  errorMsg
} from '../../../utils/error';
import {cacheBuster, clearCache} from '../utils/cache';
import { getCachedItems } from '../utils/items';

const failed = (error) => {
  errorMsg(error);
  return {
    type: ITEMS_FAILED,
    error
  }
}

const requested = () => {
  return {
    type: ITEMS_REQUESTED
  }
}

const received = (payload) => {
  return {
    type: ITEMS_RECEIVED,
    payload
  }
}

export const fetchItems = () => {
  return (dispatch, getState) => {
    dispatch(requested())

    if (!cacheBuster()) {
      dispatch(received(getCachedItems()))
    } else {

      const data = new FormData()
      data.append('items', JSON.stringify(getCachedItems()))
      console.log(data);
      clearCache('items');
      console.log(ITEMS_URL + '&lang=' + getState().languages.lang);
      axios.post(ITEMS_URL + '&lang=' + getState().languages.lang, data)
        .then((resp) => {
          if (resp.data.success) {
            dispatch(received(resp.data.items))
          } else {
            dispatch(failed(resp.data.errors))
          }
        })
        .catch((error) => {
          if (!error.response) {
            console.log(error)
          } else {
            dispatch(failed(error.response.data.errors))
          }
        })
    }
  }
}

export const addItemToStart = (item) => {
  return {
    type: ITEMS_ADD_TO_START,
    item
  }
}

export const addItemToEnd = (item) => {
  return {
    type: ITEMS_ADD_TO_END,
    item
  }
}

export const addButtonsToItem = () => {
  return {
    type: ITEMS_ADD_BUTTONS
  }
}

export const removeItem = (item) => {
  return {
    type: ITEMS_REMOVE,
    item
  }
}

export const removeItemsByRowType = (rowType) => {
  return {
    type: ITEMS_REMOVE_BY_ROW_TYPE,
    rowType
  }
}

export const editItem = (value, id, key) => {
  return {
    type: ITEMS_SIGNLE_EDIT,
    value,
    id,
    key
  }
}

export const editItems = (values) => {
  return {
    type: ITEMS_MULTIPLE_EDIT,
    values
  };
}

export const resetItems = () => {
  return {
    type: ITEMS_RESET_RESULTS
  }
}

export const replaceHeaderAndFooter = (header, footer) => {
  return {
    type: ITEMS_REPLACE_HEADER_FOOTER,
    header,
    footer
  }
}

export const placeItem = (oldIndex, newIndex) => {
  return {
    type: ITEMS_PLACE,
    oldIndex,
    newIndex
  }
}

export const sortItems = (column, desc) => ({
  type: ITEMS_SORT,
  column,
  desc
});