import {
  MODAL_OPENED,
  MODAL_CLOSED
} from './types';

export const openModal = (content, title) => ({
  type: MODAL_OPENED,
  content,
  title
});

export const closeModal = () => ({
  type: MODAL_CLOSED
});
