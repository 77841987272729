import React from 'react';
import MyExcelsActions from '../MyExcelsActions';

export const addButtons = (items) => {
    const newItems = items.map((item) => {
        item.actions = <MyExcelsActions item={item}/>
        return item 
    })

    return newItems
}

export const removeItem = (items, id) => {
    return items.filter((item) => item.id !== id)
}