import React from 'react';
import {
  Tooltip
} from 'react-bootstrap';
import {
  checkFromCache,
  getFromCache
} from './cache';
import {
  resultsByType,
  mergeUnique,
  insert
} from '../../../utils/array';
import XlsTableRemoveRowButton from '../../XlsTableRemoveRowButton/XlsTableRemoveRowButton';
import { trans } from '../../../utils/translations';
import RowDiscount from '../../RowDiscount/RowDiscount';
import MoveButton from '../../MoveButton/MoveButton';

/**
 * Add extra buttons to each item row
 * @param {array} items 
 */
export const addButtons = (items) => {
  return items.map((item) => {
    item.buttons = removeButton(item)
    if (item.rowType == 'product') {
      item.stock = addStock(item)
    }
    return item
  });
}

const addStock = (item) => {
  return trans.w + ':' + item.stock_w + ' ' + trans.s + ':' + item.stock_s + ' ' + trans.b + ':' + item.stock_b
}

const removeButton = (item) => (
  <div key={item.id}>
    &nbsp;{item.rowType == 'product' ? <RowDiscount value={item.discount_perc} item={item} /> : null}
    &nbsp;<XlsTableRemoveRowButton item={item} />
    &nbsp;<MoveButton />
  </div>
);

/**
 * Remove item by id from items
 * @param {array} items 
 * @param {string} item 
 */
export const removeItem = (items, item) => items.filter(e => e.id != item);

/**
 * Remove items by row type. 
 * Ex: product removes all products
 * @param {array} items 
 * @param {string} type 
 */
export const removeItemsByRowType = (items, type) => items.filter(e => e.rowType !== type);

/**
 * Replace item value
 * @param {array} items 
 * @param {string} value 
 * @param {string} id 
 * @param {string} key 
 */
export const replaceItemValue = (items, value, id, key) => {
  return items.map((item) => {
    item.buttons = undefined
    if (item.id === id) {
      item[key] = value
    }
    return item
  });
}

/**
 * Replaces items values with give values
 * 
 * @param {array} items 
 * @param {array} values
 */
export const replaceItemsValues = (items, values) => {
  return items.map((item) => {
    item.buttons = undefined;
    const val = values.find(o => o.id === item.id);
    if (!val) {
      return item;
    }
    return {...item, ...val};
  });
}

export const updateItems = (items, newItems) => {
  const oldItems = items.filter(item => !newItems.some(newItem => newItem.id === item.id) && item.rowType === 'product')
  return oldItems.concat(newItems)
}

export const mergeItems = (items, newItems) => {
  const oldItems = items.filter(item => !newItems.find(newItem => newItem.id === item.id))
  return oldItems.concat(newItems);
}

export const displayTooltip = (text) => (
  <Tooltip id="tooltip">
    {text}
  </Tooltip>
);

export const cacheItems = (items) => checkFromCache(items, 'items');
export const getCachedItems = () => getFromCache('items');
export const getItems = (state, action) => addButtons(cacheItems(mergeAndRemoveItems(state, action)));

export const mergeAndRemoveItems = (state, action) => {
  const oldItems = state.items
  const headers = resultsByType(oldItems, 'header')
  const footers = resultsByType(oldItems, 'footer')
  const filteredNewItems = action.payload.filter(item => item.rowType === 'product')
  const mergedItems = updateItems(state.items, filteredNewItems)
  const returnArray = headers.concat(mergedItems, footers)
  return returnArray
}

export const countItems = (items) => {
  const filtered = items.filter(item => item.rowType === 'product')
  return filtered.length
}

export const addMissingItems = (items, newItems) => checkFromCache( mergeUnique(items, newItems), 'missingItems');

export const replaceHeaderFooter = (state, action) => {
  const headers = action.header
  const footers = action.footer
  const filteredNewItems = state.items.filter(item => item.rowType === 'product')
  const returnArray = headers.concat(filteredNewItems, footers)
  return addButtons(cacheItems(returnArray))
}

/**
 * Places item to given index position
 * 
 * @param {object} state 
 * @param {int} oldIndex 
 * @param {int} newIndex 
 */
export const placeByIndex = (state, oldIndex, newIndex) => {
  const items = [...state.items];
  const currentItem = items[oldIndex];
  items.splice(oldIndex, 1);
  const newItems = insert(items, newIndex, currentItem);
  return addButtons(cacheItems(newItems));
}

/**
 * Sorts items by given column
 * 
 * @param {object} state 
 * @param {object} column 
 * @param {boolean} desc 
 */
export const sortByColumn = (state, column, desc) => {
  const items = [...state.items];
  items.sort((a, b) => {
    if (column.sortMethod !== undefined) {
      return column.sortMethod(a[column.id], b[column.id]);
    } 
    return a[column.id] - b[column.id];
  });
  if (desc) {
    items.reverse();
  }
  return addButtons(cacheItems(items));
}