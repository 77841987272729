import {
    items
} from './items';
import {
    columns
} from './columns';
import {
    selectColumns
} from './selectColumns';
import {
    excel
} from './excel';
import {
    languages
} from './languages';
import {
    templates
} from './templates';
import {
    notFoundClicked
} from './notFoundClicked';
import {
    modal
} from './modal';

export default {
    items,
    columns,
    selectColumns,
    excel,
    languages,
    templates,
    notFoundClicked,
    modal
}