import axios from 'axios';
import {
    ITEMS_BY_ID_URL
} from '../../../../../config/config';
import {
    toastr
} from 'react-redux-toastr';
import {
    ITEMS_BY_URL_REQUESTED,
    ITEMS_BY_URL_RECEIVED,
    ITEMS_BY_URL_FAILED,
} from './types';
import {
    errorMsg
} from '../../../utils/error';
import {
    hasQueryString
} from '../../../utils/helpers';
import {
    trans
} from '../../../utils/translations';

const failed = (error) => {
    errorMsg(error);
    return {
        type: ITEMS_BY_URL_FAILED,
        error
    }
}

const requested = () => {
    return {
        type: ITEMS_BY_URL_REQUESTED
    }
}

const received = (payload, missingItems) => {
    return {
        type: ITEMS_BY_URL_RECEIVED,
        payload,
        missingItems
    }
}

/**
 * Fetch items by url from elastic controller
 * @param {string} url 
 * @param {bool} all
 */
export const fetchItemsByUrl = (url, all) => {
    return (dispatch, getState) => {
        dispatch(requested())

        axios.post(hasQueryString(url) + 'ndoxls_request=1' + (all ? '&all=1' : ''))
            .then((resp) => {
                if (resp.data.success) {
                    //fetch items using returned ids
                    fetchItemsByIds(
                        resp.data.items,
                        getState().languages.lang
                    ).then((response) => {
                        toastr.success(trans.successfully, trans.successfullyAdded)
                        dispatch(received(response.items, response.missingItems))
                    }).catch((error) => {
                        console.log(error)
                        dispatch(failed(error))
                    })

                } else {
                    dispatch(failed(resp.data.errors))
                }
            })
            .catch((error) => {
                if (!error.response) {
                    console.log(error)
                } else {
                    dispatch(failed(error.response.data.errors))
                }
            })
    }
}

/**
 * Get items data by ids
 * @param {array} ids 
 * @param {string} lang 
 */
const fetchItemsByIds = (ids, lang) => {
    return new Promise((resolve, reject) => {
        let data = new FormData()
        data.append('ids', JSON.stringify(ids))

        axios.post(ITEMS_BY_ID_URL + '&lang=' + lang, data)
            .then((resp) => {
                if (resp.data.success) {
                    resolve(resp.data)
                } else {
                    reject(resp.data.errors)
                }
            })
            .catch((error) => {
                if (!error.response) {
                    console.log(error)
                } else {
                    reject(error.response.data.errors)
                }
            })
    })
}

/**
 * Fetch items that where chosen using 1 in product list input
 * 
 * @param {array} items 
 */
export const fetchItemsByChosen = (items) => {
    return (dispatch, getState) => {
        dispatch(requested())

        fetchItemsByIds(
            items,
            getState().languages.lang
        ).then((response) => {
            toastr.success(trans.successfully, trans.successfullyAdded)
            dispatch(received(response.items, response.missingItems))
        }).catch((error) => {
            console.log(error)
            dispatch(failed(error))
        })
    }
}