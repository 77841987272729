import {
    SAVE_EXCEL_REQUESTED,
    SAVE_EXCEL_RECEIVED,
    SAVE_EXCEL_FAILED,
    EXCEL_GENERATE_START,
    EXCEL_GENERATE_END
} from '../actions/types';

const initialState = {
    saving: false,
    error: {}
}

export const excel = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_EXCEL_REQUESTED:
            return { ...state,
                saving: true,
                error: {}
            }
        case SAVE_EXCEL_RECEIVED:
            return { ...state,
                saving: false,
                error: {}
            }
        case SAVE_EXCEL_FAILED:
            return { ...state,
                saving: false,
                error: action.error
            }
        case EXCEL_GENERATE_START:
            return { ...state,
                generating: true
            }
        case EXCEL_GENERATE_END:
            return { ...state,
                generating: false
}
    }

    return state
}