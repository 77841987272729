import * as Excel from 'exceljs/dist/exceljs.min.js';
import {
    saveAs
} from 'file-saver';
import axios from 'axios';
import {
    generateStart,
    generateEnd
} from '../actions/excel';
import {
    arrayReplaceUsingKeys
} from '../../../utils/array';

export const exportExcel = (rows, columns, dispatch) => {
    exportExcelWithFilename(createExcelFilename(), rows, columns, dispatch)
}

export const exportExcelWithFilename = (filename, rows, columns, dispatch) => {

    dispatch(generateStart())

    let wb = new Excel.Workbook({
        useStyles: true
    })
    let ws = wb.addWorksheet('Worksheet')
    ws.pageSetup.verticalCentered = true
    ws.views = [{}]

    //make compitable with older versions of table structure
    columns = backwardsCompitableColumns(columns)

    //we include columns only if includeInExcel is true
    columns = includeOnlyInExcel(columns)

    //image column position
    const imageColumnPosition = getImageColumnPosition(columns)

    if (imageColumnPosition !== -1) {
        ws.properties.defaultRowHeight = 46
    }

    formatRows(rows, columns).then((resp) => {
        let i = countRowsByType(rows, 'header') + 1
        rows.map((row) => {
            if (row.image === '') {
                i++
                return
            }
            let image = wb.addImage({
                base64: row.image,
                extension: 'jpeg'
            })
            ws.addImage(image, {
                tl: {
                    col: imageColumnPosition + 0.05,
                    row: i + 0.05,
                    colOff: 15,
                    rowOff: 1
                },
                br: {
                    col: imageColumnPosition + 0.95,
                    row: i + 1,
                    colOff: 65,
                    rowOff: -5
                },
                editAs: 'absolute'
            })
            i++
        });

        ws.columns = formatColumns(columns, rows)
        ws.addRows(resp.rows)

        //styles, alignment etc.
        wsStyle(ws)

        wb.xlsx.writeBuffer().then((data) => {
            const blob = new Blob([data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }) //application/octet-stream
            saveAs(blob, filename + ".xlsx")
            dispatch(generateEnd())
        })

    }).catch((error) => {
        console.log(error)
        alert('Ühte piltidest ei leitud');
    })
}

/**
 * Format rows
 * @param {array} rows 
 * @param {array} columns 
 */
export const formatRows = (rows, columns) => {
    return new Promise((resolve, reject) => {
        let xRows = []
        rows.map((row) => {
            let r = {}
            columns.map((col) => {
                if (col.accessor !== 'buttons') {
                    r[col.id] = formatFieldOutput(row, col)
                }
            })

            xRows = [...xRows, r]
        })

        resolve({
            rows: xRows
        })
    })
}

/**
 * Different types columns have different output
 * @param {object} row 
 * @param {object} col 
 */
const formatFieldOutput = (row, col) => {
    if (col.accessor === 'link' && row.rowType === 'product') {
        return {
            text: row.link,
            hyperlink: row.link
        }
    } else if (col.accessor === 'link_to_product' && row.rowType === 'product') {
        return {
            text: row.link_to_product_text,
            hyperlink: row.link
        }
    } else if (col.accessor === 'image' && row.rowType === 'product') {
        return ''
    } else {
        row.alignment = {
            vertical: 'middle'
        }
        if (row[col.id]) {
            return row[col.id]
        } else {
            return row[col.accessor]
        }
    }
}

/**
 * Search through all rows and gets the longest value length
 * @param {array} rows 
 * @param {string} ckey 
 */
export const getColumnWidth = (rows, col) => {
    let response = 0
    rows.map((row) => {
        let length = 0
        if (row[col.id] === undefined) {
            if (row[col.accessor] !== undefined) {
                length = row[col.accessor].length + 2
            }
        } else {
            length = row[col.id].length + 2
        }

        if (response < length) {
            response = length
        }

        if (col.accessor === 'image') {
            response = 12
        }

        if (col.accessor === 'sizes') {
            response = 20
        }

        if (col.accessor === 'link_to_product') {
            response = 15
        }
    })

    if (response < 12) {
        response = 15
    }

    return response
}

/**
 * Format columns array for exceljs
 * @param {array} columns 
 * @param {array} rows 
 */
export const formatColumns = (columns, rows) => {
    let newColumns = []
    columns.map((column) => {
        if (column.accessor === 'buttons' || !column.includeInExcel) {
            return;
        }
        newColumns = [...newColumns, {
            header: column.originalHeader  ? column.originalHeader : column.Header,
            key: column.id,
            width: getColumnWidth(rows, column)
        }]
    })

    return newColumns
}

export const countRowsByType = (rows, type) => parseInt(rows.filter(r => r.rowType === type).length);
export const getImageColumnPosition = (columns) => columns.findIndex(c => c.accessor == 'image');
export const includeOnlyInExcel = (columns) => columns.filter(c => c.includeInExcel);

/**
 * Worksheet style
 * @param {object} ws 
 */
export const wsStyle = (ws) => {
    ws.eachRow(function(row, rowNum) {

        if (ws.properties.defaultRowHeight) {
            ws.getRow(rowNum).height = ws.properties.defaultRowHeight
        }

        row.eachCell(function(cell, cellNum) {

            if (rowNum == 1) {
                cell.alignment = {
                    vertical: 'middle',
                    horizontal: 'center'
                }
            } else {
                cell.alignment = {
                    vertical: 'middle',
                    horizontal: 'left'
                }
            }
        })
    })
}

/**
 * Generate excel filename 
 * Format: ND-0000-00-00
 * 
 * @return {string}
 */
export const createExcelFilename = () => {
    let dt = new Date()
    const name = 'ND-' + dt.getFullYear() + "-" + (dt.getMonth() + 1) + "-" + dt.getDate() + '-' + new Date().getTime()

    return name;
}

/**
 * Make columns work with older versions of table
 * 
 * @param {array} columns
 * @return array 
 */
export const backwardsCompitableColumns = (columns) => {
    return columns.map((c) => {
        if (c.includeInExcel === undefined) {
            c.includeInExcel = true
        }

        return c
    });
}
