import {
    MY_EXCELS_REQUESTED,
    MY_EXCELS_RECEIVED,
    MY_EXCELS_FAILED,
    DELETE_MY_EXCEL_REQUESTED,
    DELETE_MY_EXCEL_RECEIVED,
    DELETE_MY_EXCEL_FAILED
} from '../actions/types';
import { removeItem, addButtons } from '../utils/items';

const initialState = {
    fetching: false,
    items: [],
    itemsCount: 0,
    pages: 1,
    pageSize: 10,
    error: {}
}

export const myExcels = (state = initialState, action) => {
    switch (action.type) {
        case MY_EXCELS_REQUESTED:
            return { ...state,
                fetching: true,
                error: {}
            }
        case MY_EXCELS_RECEIVED:
            return { ...state,
                fetching: false,
                error: {},
                items: addButtons(action.items),
                itemsCount: action.itemsCount,
                pages: Math.ceil(action.itemsCount / action.pageSize),
                pageSize: action.pageSize
            }
        case MY_EXCELS_FAILED:
            return { ...state,
                fetching: false,
                error: action.error
            }
        case DELETE_MY_EXCEL_REQUESTED:
            return { ...state,
                fetching: true,
                error: {}
            }
        case DELETE_MY_EXCEL_RECEIVED:
            return { ...state,
                fetching: false,
                error: {},
                items: removeItem(state.items, action.id)
            }
        case DELETE_MY_EXCEL_FAILED:
            return { ...state,
                fetching: false,
                error: action.error
            }
    }

    return state;
}