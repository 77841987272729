import React from 'react'
import {
  insertToArray,
  indexFromArray
} from "../../../utils/array"
import {
  checkFromCache,
  getFromCache
} from './cache';
import NHMargin from '../../NHMargin/NHMargin';
import OHMargin from '../../OHMargin/OHMargin';
import DiscountPrice from '../../DiscountPrice/DiscountPrice';
import RRP from '../../RRP/RRP';
import DiscountRRP from '../../DiscountRRP/DiscountRRP';
import SortableColumn from '../../SortableColumn/SortableColumn';
import {
  addSortMethod
} from './sortMethods';
import VOMargin from "../../VOMargin/VOMargin";

/**
 * Add column by id from columns
 * @param {array} columns 
 * @param {object} column 
 * @param {object} currentColumn
 */
export const addColumn = (columns, column, currentColumn) => {
  if (currentColumn === null) {
    return [column, ...columns]
  } else {
    return insertToArray([...columns], indexFromArray(
      [...columns],
      currentColumn
    ), column)
  }
}

/**
 * Remove column by id from columns
 * @param {array} columns 
 * @param {string} column 
 */
export const removeColumn = (columns, column) => columns.filter(e => e.id != column);

export const addCell = (columns, callback) => columns.map((column) => {
  if (column.id === 'buttons') {
    return column
  }
  column.Cell = callback
  return column;
});

/**
 * Update Cell value of column based on column type
 * Use custom output for table columns
 * @param {array} columns 
 */
export const updateCell = (columns) => {
  return columns.map((column) => {

    // add sort button to header and sort method
    column.sortMethod = undefined;
    if (column.originalHeader !== undefined) {
      column.Header = column.originalHeader;
    }

    if (column.sortable !== false) {
      column.originalHeader = column.Header;
      column.Header = <SortableColumn header={column.Header} />;
      column = addSortMethod(column);
    }

    if (column.accessor === 'link') {
      column.Cell = row => (
        <div>{row.original.rowType === 'product' ? <a href={row.value} target="_blank">{row.value}</a> : row.value}</div>
      )
    }

    if (column.accessor === 'link_to_product') {
      column.Cell = row => (
        <div>{row.original.rowType === 'product' ? <a href={row.value} target="_blank">{row.original.link_to_product_text}</a> : row.value}</div>
      )
    }

    if (column.accessor === 'image') {
      column.Cell = row => (
        <div>{row.original.rowType === 'product' ? <img src={row.value} alt="" height="25" /> : (row.value ? row.value : row.original[column.id])}</div>
      )
    }

    if (column.accessor === 'price_nh') {
      column.Cell = row => (
        <div>{row.original.rowType === 'product' ? <NHMargin row={row} /> : row.value}</div>
      )
    }

    if (column.accessor === 'discount_price') {
      column.Cell = row => (
        <div>{row.original.rowType === 'product' ? <DiscountPrice row={row} value={row.value} column={column.accessor} /> : row.value}</div>
      )
    }

    if (column.accessor === 'rrp') {
      column.Cell = row => (
        <div>{row.original.rowType === 'product' ? <RRP row={row} value={row.value} column={column.accessor} /> : row.value}</div>
      )
    }

    if (column.accessor === 'discount_rrp') {
      column.Cell = row => (
          <div>{row.original.rowType === 'product' ? <DiscountRRP row={row} value={row.value} column={column.accessor} /> : row.value}</div>
      )
    }

    if (column.accessor === 'price_nh') {
      column.Cell = row => (
        <div>{row.original.rowType === 'product' ? <NHMargin row={row} /> : row.value}</div>
      )
    }

    if (column.accessor === 'price_oh') {
      column.Cell = row => (
          <div>{row.original.rowType === 'product' ? <OHMargin row={row} /> : row.value}</div>
      )
    }
    if (column.accessor === 'price_vo') {
      column.Cell = row => (
          <div>{row.original.rowType === 'product' ? <VOMargin row={row} /> : row.value}</div>
      )
    }

    if (column.accessor === 'client_markup') {
      column.Cell = row => (
        <div>{row.original.rowType === 'product' ? row.value + '%' : row.value}</div>
      )
    }

    return column
  });
}

export const updateItems = (items, newItems) => 
  items.filter(item => !newItems.some(newItem => newItem.id === item.id))
    .concat(newItems);

/**
 * Replaces accessor with id for certain types
 * Example: text with id
 * @param {array} columns 
 * @return {array}
 */
export const columnsReplaceAccessorWithId = (columns) => columns.map((col) => {
  if (col.accessor !== 'text') {
    return col;
  }
  col.accessor = col.id
  return col;
});

/**
 * Removes used columns from select columns, except text
 * Text can be used multiple times
 * @param {array} selectColumns 
 * @param {array} columns 
 * @return {array}
 */
export const removeUsedColumns = (selectColumns, columns) => 
  selectColumns.filter(sc => !columns.some(c => c.id === sc.id && c.accessor !== 'text'));

/**
 * Cache columns
 * @param {array} columns 
 * @return {array}
 */
export const cacheColumns = (columns) => checkFromCache(columns, 'columns');

/**
 * Get columns from cache
 * @return {array}
 */
export const getCachedColumns = () => getFromCache('columns');

export const mergeWithCache = (items, newItems) => 
  updateCell(cacheColumns(updateItems(items, newItems)));

export const cleanHeaders = (items) => items.map((item) => {
  console.log(item);
  item.originalHeader = undefined;
  return item;
});