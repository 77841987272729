import React from 'react';
import {
    MODAL_OPENED,
    MODAL_CLOSED
} from '../actions/types';
import Loading from '../../Loading/Loading';
import { trans } from '../../../utils/translations';

const initialState = {
    show: false,
    content: <Loading />,
    title: trans.loading + '...'
}

export const modal = (state = initialState, action) => {
    switch (action.type) {
        case MODAL_OPENED:
            return { ...state,
                show: true,
                content: action.content,
                title: action.title
            }
        case MODAL_CLOSED:
            return { ...state,
                show: false,
                content: <Loading />,
                title: trans.loading + '...'
            }
    }

    return state;
}