import {
    toastr
} from 'react-redux-toastr';

export const errorMsg = (error) => {
    if (error) {
        if (Array.isArray(error)) {
            for (let key in error) {
                toastr.error('Viga', error[key][0]);
            }
        } else {
            toastr.error('Viga', error);
        }
    }
}