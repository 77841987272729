import {
    SAVE_TEMPLATE_REQUESTED,
    SAVE_TEMPLATE_RECEIVED,
    SAVE_TEMPLATE_FAILED
} from '../actions/types';

const initialState = {
    fetching: true,
    items: [],
    error: {}
}

export const templates = (state = initialState, action) => {
    switch (action.type) {
        case SAVE_TEMPLATE_REQUESTED:
            return { ...state,
                fetching: true,
                error: {}
            }
        case SAVE_TEMPLATE_RECEIVED:
            return { ...state,
                fetching: false,
                error: {}
            }
        case SAVE_TEMPLATE_FAILED:
            return { ...state,
                fetching: false,
                error: action.error
            }
    }

    return state;
}