import React, {
    Component
} from 'react';
import PropTypes from 'prop-types';
import {
    connect
} from 'react-redux';
import {
    Button,
} from 'react-bootstrap';
import FontAwesomeIcon from '../FontAwesomeIcon/FontAwesomeIcon';
import {
    trans
} from '../../utils/translations';
import {
    exportExcelWithFilename
} from '../XlsTable/utils/excel';
import Loading from '../Loading/Loading';


class MyExcelsActions extends Component {

    constructor(props) {
        super(props)

        this.handleDownloadClick = this.handleDownloadClick.bind(this)
    }

    handleDownloadClick() {
        const {
            dispatch,
            item
        } = this.props

        exportExcelWithFilename(
            item.net_name,
            JSON.parse(item.items),
            JSON.parse(item.columns),
            dispatch
        )
    }

    render() {
        const {
            saving
        } = this.props
        return (
            <div>
                <Button onClick={this.handleDownloadClick}><FontAwesomeIcon icon="download"/> {saving?<Loading />:trans.download}</Button>
            </div>
        )
    }

}

MyExcelsActions.propTypes = {
    item: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return { 
        saving: state.excel.saving
    }
}

export default connect(
    mapStateToProps,
    null
)(MyExcelsActions);