import React from 'react';
import FontAwesomeIcon from '../FontAwesomeIcon/FontAwesomeIcon';
import './scss/style.scss';

const SortableColumn = ({ header }) => ( 
  <div className="sortable-column">
    <a href="#" onClick={(e) => { e.preventDefault(); } }>
      <FontAwesomeIcon icon="sort" />
    </a>
    {header}
  </div>
);

export default React.memo(SortableColumn);
