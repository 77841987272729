import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { calcClientMarkup } from '../../utils/calculations'
import { editItem } from '../XlsTable/actions/items'

class DiscountRRP extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            editing: false
        }

        this.handleOnDoubleClick = this.handleOnDoubleClick.bind(this)
        this.handleOnDown = this.handleOnDown.bind(this)
    }

    handleOnDoubleClick(e) {
        this.setState({
            editing: true
        })
    }

    handleOnDown(e) {
        if (e.key !== 'Enter') {
            return false
        }

        const {
            row,
            dispatch
        } = this.props

        const item = row.original

        if (item.rrp === e.target.value) {
            this.setState({
                editing: false
            })
            return false
        }
        const clientMarkup = calcClientMarkup(e.target.value, item.discount_price, item.tax)
        dispatch(editItem(e.target.value, item.id, 'discount_rrp'))
        dispatch(editItem(clientMarkup, item.id, 'client_markup'))

        this.setState({
            editing: false
        })
    }

    render() {
        const {
            editing
        } = this.state

        const {
            value
        } = this.props

        const valueField = <span onDoubleClick={this.handleOnDoubleClick}>{value}</span>

        return (
            <div>{editing ? <input
                type="text"
                autoFocus
                defaultValue={value}
                onKeyDown={this.handleOnDown}
            /> : valueField}</div>
        )
    }
}

export default connect()(DiscountRRP)