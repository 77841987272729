import {
  COLUMNS_REQUESTED,
  COLUMNS_RECEIVED,
  COLUMNS_FAILED,
  COLUMNS_RESET_RESULTS,
  COLUMNS_ADD,
  COLUMNS_REMOVE,
  COLUMNS_EDITABLE_CELL_ADD,
  COLUMNS_ADD_DISPLAY_TYPES,
  COLUMNS_REBUILD,
  COLUMNS_CLEAN_HEADER
} from '../actions/types';
import {
  removeColumn,
  addColumn,
  addCell,
  updateCell,
  columnsReplaceAccessorWithId,
  cacheColumns,
  getCachedColumns,
  mergeWithCache,
  cleanHeaders
} from '../utils/columns';

const initialState = {
  fetching: false,
  items: updateCell(getCachedColumns()),
  error: {}
}

export const columns = (state = initialState, action) => {
  switch (action.type) {
    case COLUMNS_REQUESTED:
      return {
        ...state,
        fetching: true,
        error: {}
      }
    case COLUMNS_RECEIVED:
      return {
        ...state,
        fetching: false,
        error: {},
        items: updateCell(cacheColumns(action.payload)) 
      }
    case COLUMNS_ADD:
      return {
        ...state,
        fetching: false,
        error: {},
        items: mergeWithCache(state.items, columnsReplaceAccessorWithId(addColumn(
          state.items,
          action.column,
          action.currentColumn
        )))
      }
    case COLUMNS_REMOVE:
      return {
        ...state,
        fetching: false,
        error: {},
        items: cacheColumns(removeColumn(state.items, action.column))
      }
    case COLUMNS_FAILED:
      return {
        ...state,
        fetching: false,
        error: action.error
      }
    case COLUMNS_RESET_RESULTS:
      return {
        ...state,
        fetching: false,
        items: [],
        error: {}
      }
    case COLUMNS_EDITABLE_CELL_ADD:
      return {
        ...state,
        fetching: false,
        error: {},
        items: cacheColumns(addCell(state.items, action.callback))
      }
    case COLUMNS_ADD_DISPLAY_TYPES:
      return {
        ...state,
        fetching: false,
        error: {},
        items: updateCell(cacheColumns(state.items))
      }
    case COLUMNS_REBUILD:
      return {
        ...state,
        fetching: false,
        error: {},
        items: updateCell(cacheColumns(action.columns))
      }
    case COLUMNS_CLEAN_HEADER:
      return {
        ...state,
        fetching: false,
        error: {},
        items: cleanHeaders(state.items)
      }
  }

  return state;
}