import axios from 'axios';
import {
    DELETE_MY_TEMPLATE_URL
} from '../../../../../config/config';
import {
    DELETE_MY_TEMPLATE_REQUESTED,
    DELETE_MY_TEMPLATE_RECEIVED,
    DELETE_MY_TEMPLATE_FAILED
} from './types';
import {
    errorMsg
} from '../../../utils/error';

const failed = (error) => {
    errorMsg(error);
    return {
        type: DELETE_MY_TEMPLATE_FAILED,
        error: error
    }
}

const requested = () => {
    return {
        type: DELETE_MY_TEMPLATE_REQUESTED
    }
}

const received = (id) => {
    return {
        type: DELETE_MY_TEMPLATE_RECEIVED,
        id: id
    }
}

export const deleteMyTemplate = (id) => {
    return (dispatch) => {
        dispatch(requested())

        let data = new FormData()
        data.append('id', id)

        axios.post(DELETE_MY_TEMPLATE_URL, data)
            .then((resp) => {
                if (resp.data.success) {
                    dispatch(received(id))
                } else {
                    dispatch(failed(resp.data.errors))
                }
            })
            .catch((error) => {
                dispatch(failed(error.response.data.errors))
            })
    }
}