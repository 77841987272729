import React, { PureComponent } from 'react'
import { calcDiscountPrice, calcMarginPerc, calcClientMarkup } from '../../utils/calculations'
import { editItems } from '../XlsTable/actions/items'
import { connect } from 'react-redux'
import { trans } from '../../utils/translations'

class RowDiscount extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      item: props.item,
      value: props.value,
      typing: null,
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.inputRef = React.createRef();
  }

  handleOnKeyDown(e) {
    if (e.key !== 'Enter') {
      return false;
    }
    this.updateItem(e.target.value);
  }

  updateItem(perc) {
    const {
      item,
      dispatch
    } = this.props

    if (perc === '') {
      perc = 0
    }
    const newPrice = calcDiscountPrice(parseInt(perc), item.orig_discount_price);
    const margin = calcMarginPerc(newPrice, item.price_nh);
    const oh_margin = calcMarginPerc(newPrice, item.price_oh);
    const vo_margin = calcMarginPerc(newPrice, item.price_vo);
    const clientMarkup = calcClientMarkup(item.rrp, newPrice, item.tax);
    const replaceValues = {
      id: item.id,
      discount_price: newPrice,
      margin_perc: margin,
      oh_margin_perc: oh_margin,
      vo_margin_perc: vo_margin,
      discount_perc: perc,
      client_markup: clientMarkup,
    };
    dispatch(editItems([replaceValues]));
  }

  handleOnChange(e) {
    const {
      item,
      typing
    } = this.state;
    clearInterval(typing);
    item.discount_perc = e.target.value;
    this.setState({
      item: item,
      value: e.target.value,
      typing: true
    });
  }

  componentWillReceiveProps(nextProps, state) {
    this.setState({
      item: nextProps.item,
      value: nextProps.item.discount_perc
    })
  }

  render() {
    const {
      value
    } = this.state;

    let val = value
    if (val == 0) {
      val = '';
    }

    return (
      <span><input
        type="text"
        onKeyDown={this.handleOnKeyDown}
        placeholder={trans.SP}
        style={{ width: 30 }}
        value={val}
        onChange={this.handleOnChange}
        ref={this.inputRef}
      />%</span>
    );
  }
}

export default connect()(RowDiscount)