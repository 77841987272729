import React, {
    PureComponent
} from 'react';
import {
    connect
} from 'react-redux';
import {
    Button
} from 'react-bootstrap';
import './scss/style.scss';
import {
    trans
} from '../../utils/translations';
import {
    ndoData
} from '../../utils/ndo';
import PopupModal from '../Modal/Modal';
import {
    closeModal,
    openModal
} from '../XlsTable/actions/modal';
import CartButtonsPopup from './CartButtonsPopup';

class Cart extends PureComponent {

    constructor(props) {
        super(props)

        this.handleHideModal = this.handleHideModal.bind(this)
        this.handlePreButtonClick = this.handlePreButtonClick.bind(this)
    }

    handleHideModal() {
        this.props.dispatch(closeModal())
    }

    handlePreButtonClick() {
        this.props.dispatch(openModal(
            <CartButtonsPopup />,
            trans.addToExcel
        ))
    }

    render() {
        const {
            modalTitle,
            modalContent,
            showModal
        } = this.props

        return (
            <div>
                <Button className="view-button nodxls-add-btn" onClick={this.handlePreButtonClick}>
                    {trans.addToExcel}
                </Button>
                {showModal ? <PopupModal 
                    handleHideModal={this.handleHideModal.bind(this)} 
                    title={modalTitle}
                    classes="buttons-popup">{modalContent}
                </PopupModal> : ''}
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        showModal: state.modal.show,
        modalContent: state.modal.content,
        modalTitle: state.modal.title
    }
}

export default connect(
    mapStateToProps,
    null
)(Cart);