import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { calcPriceDiscountPerc, calcMarginPerc, calcClientMarkup } from '../../utils/calculations'
import { editItem } from '../XlsTable/actions/items'

class DiscountPrice extends PureComponent {

    constructor(props) {
        super(props)

        this.state = {
            editing: false
        }

        this.handleOnDoubleClick = this.handleOnDoubleClick.bind(this)
        this.handleOnDown = this.handleOnDown.bind(this)
    }

    handleOnDoubleClick(e) {
        this.setState({
            editing: true
        })
    }

    handleOnDown(e) {
        if (e.key !== 'Enter') {
            return false
        }
        
        const {
            row, 
            dispatch
        } = this.props

        const item = row.original

        if (item.discount_price === e.target.value) {
            this.setState({
                editing: false
            })
            return false
        }

        const perc = calcPriceDiscountPerc(item.orig_discount_price, e.target.value)
        const margin = calcMarginPerc(e.target.value, item.price_nh)
        const clientMarkup = calcClientMarkup(item.rrp, e.target.value, item.tax)
        dispatch(editItem(perc, item.id, 'discount_perc'))
        dispatch(editItem(e.target.value, item.id, 'discount_price'))
        dispatch(editItem(margin, item.id, 'margin_perc'))
        dispatch(editItem(clientMarkup, item.id, 'client_markup'))
        
        
        this.setState({
            editing: false
        })
    }

    render() {
        const {
            editing
        } = this.state

        const {
            value
        } = this.props

        const valueField = <span onDoubleClick={this.handleOnDoubleClick}>{value}</span>

        return (
            <div>{editing ? <input 
                type="text" 
                autoFocus 
                defaultValue={value} 
                onKeyDown={this.handleOnDown}
                /> : valueField}</div>
        )
    }
}

export default connect()(DiscountPrice)