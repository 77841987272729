import axios from 'axios';
import {
  COLUMNS_URL
} from '../../../../../config/config';
import {
  COLUMNS_REQUESTED,
  COLUMNS_RECEIVED,
  COLUMNS_FAILED,
  COLUMNS_RESET_RESULTS,
  COLUMNS_ADD,
  COLUMNS_REMOVE,
  COLUMNS_EDITABLE_CELL_ADD,
  COLUMNS_ADD_DISPLAY_TYPES,
  COLUMNS_REBUILD,
  COLUMNS_CLEAN_HEADER
} from './types';
import {
  errorMsg
} from '../../../utils/error';
import { getFromCache } from '../utils/cache';

const failed = (error) => {
  errorMsg(error);
  return {
    type: COLUMNS_FAILED,
    error
  }
}

const requested = () => ({
  type: COLUMNS_REQUESTED
});

const received = (payload) => ({
  type: COLUMNS_RECEIVED,
  payload
});

export const fetchColumns = () => {
  return (dispatch, getState) => {
    dispatch(requested());

    const {
      columns,
      languages
    } = getState();

    const cacheColumns = getFromCache('columns');
    const formData = new FormData();
    formData.append('columns', JSON.stringify(cacheColumns.length !== 0 ? cacheColumns : columns.items));

    axios.post(COLUMNS_URL + '&lang=' + languages.lang, formData)
      .then((resp) => {
        const { data } = resp;
        if (data.success) {
          dispatch(received(data.items));
        } else {
          dispatch(failed(data.errors));
        }
      })
      .catch((error) => {
        if (!error.response) {
          console.log(error);
        } else {
          dispatch(failed(error.response.data.errors));
        }
      });
  };
}

export const resetColumns = () => ({
  type: COLUMNS_RESET_RESULTS
});

export const addColumn = (column, currentColumn) => ({
  type: COLUMNS_ADD,
  column,
  currentColumn
});

export const removeColumn = (column) => ({
  type: COLUMNS_REMOVE,
  column
});

export const addEditableCells = (editableCellHandler) => ({
  type: COLUMNS_EDITABLE_CELL_ADD,
  callback: editableCellHandler
});

export const addColumnsDisplayTypes = () => ({
  type: COLUMNS_ADD_DISPLAY_TYPES
});

export const rebuildColumns = (columns) => ({
  type: COLUMNS_REBUILD,
  columns
});

export const cleanColumnHeaders = () => ({
  type: COLUMNS_CLEAN_HEADER
});
