import axios from 'axios';
import {
    SAVE_EXCEL_URL
} from '../../../../../config/config';
import {
    SAVE_EXCEL_REQUESTED,
    SAVE_EXCEL_RECEIVED,
    SAVE_EXCEL_FAILED,
    EXCEL_GENERATE_START,
    EXCEL_GENERATE_END
} from './types';
import {
    errorMsg
} from '../../../utils/error';
import { getFromCache } from '../utils/cache';
import { trans } from '../../../utils/translations';


const failed = (error) => {
    errorMsg(error);
    return {
        type: SAVE_EXCEL_FAILED,
        error
    }
}

const requested = () => {
    return {
        type: SAVE_EXCEL_REQUESTED
    }
}

const received = () => {
    return {
        type: SAVE_EXCEL_RECEIVED
    }
}

export const saveExcel = (name)  => (dispatch, getState) => {
    return new Promise((resolve, reject) => {
        dispatch(requested())

        const data = new FormData();
        data.append('items', JSON.stringify(getFromCache('items')))
        data.append('columns', JSON.stringify(getFromCache('columns')))
        data.append('name', name)

        axios.post(SAVE_EXCEL_URL + '&lang=' + getState().languages.lang, data)
            .then((resp) => {
                if (resp.data.success) {
                    dispatch(received())
                    resolve('success')
                } else {
                    dispatch(failed(resp.data.errors))
                    reject(resp.data.errors)
                }
            })
            .catch((error) => {
                if (!error.response) {
                    console.log(error)
                } else {
                    dispatch(failed(error.response.data.errors))
                }
                reject(trans.savingExcelFailed)
            })
    })
}

export const generateStart = () => {
    return {
        type: EXCEL_GENERATE_START
    }
}

export const generateEnd = () => {
    return {
        type: EXCEL_GENERATE_END
    }
}
