import React,  { PureComponent } from 'react'

class VOMargin extends PureComponent
{
    render() {

        const {
            row
        } = this.props

        let margin = '+' + row.original.vo_margin_perc
        let color = '#37913b'
        if (row.original.vo_margin_perc < 0) {
            color = '#fc1c03'
            margin = row.original.vo_margin_perc
        }
        console.log(row);

        return (
            <span>
                {row.value} <span style={{ color: color}}>{margin}%</span>
            </span>
        )
    }
}

export default VOMargin