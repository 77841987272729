import React, { useContext } from 'react'
import FontAwesomeIcon from '../FontAwesomeIcon/FontAwesomeIcon';
import { MyMoveContext } from '../../utils/context';
import './scss/style.scss';

export default () => {
  const context = useContext(MyMoveContext);
  return (
      <span
        className="move-btn"
        {...context.dragHandleProps}
      > 
        <FontAwesomeIcon icon="ellipsis-v" />
      </span>
  );
};