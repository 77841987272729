import React, {
    PureComponent
} from 'react'
import {
    connect
} from 'react-redux'
import {
    Button,
    OverlayTrigger
} from 'react-bootstrap'
import {
    toastr
} from 'react-redux-toastr'
import {
    countItems,
    displayTooltip
} from '../XlsTable/utils/items'
import {
    fetchItemsByUrl,
    fetchItemsByChosen
} from '../XlsTable/actions/fetchItemsByUrl'
import Loading from '../Loading/Loading'
import {
    trans
} from '../../utils/translations'
import {
    ndoData
} from '../../utils/ndo'

class CartButtonsPopup extends PureComponent {

    constructor(props) {
        super(props)

        this.handleAddFromCurrentPageClick = this.handleAddFromCurrentPageClick.bind(this)
        this.handleAddFromAllPages = this.handleAddFromAllPages.bind(this)
        this.handleAddChosenClick = this.handleAddChosenClick.bind(this)
    }

    handleAddFromCurrentPageClick() {
        this.props.dispatch(fetchItemsByUrl(window.location.href, false))
    }

    handleAddFromAllPages() {
        this.props.dispatch(fetchItemsByUrl(window.location.href, true))
    }

    handleAddChosenClick() {

        if (ndoxlsSelectedProducts === undefined || ndoxlsSelectedProducts.length === 0) {
            toastr.error(trans.error, trans.noProductsChosen)
            return false
        }

        this.props.dispatch(fetchItemsByChosen(ndoxlsSelectedProducts))
    }

    render() {
        const {
            items,
            fetching
        } = this.props
        return (
            <div className="buttons-popup">
                <div>
                    <h4>{trans.currentProductCountInExcel} 
                    <OverlayTrigger placement="top" overlay={displayTooltip(trans.goToExcelGeneration)}>
                        <a href={ndoData.baseUrl} className="popup-link">{countItems(items)}</a>
                    </OverlayTrigger>
                    </h4>
                </div>
                <div>
                    <Button className="view-button nodxls-add-btn" onClick={this.handleAddChosenClick}>
                        {fetching ? <Loading /> : trans.addChosen}
                    </Button>
                    <Button className="view-button nodxls-add-btn" onClick={this.handleAddFromCurrentPageClick}>
                        {fetching ? <Loading /> : trans.addFromCurrentPage}
                    </Button>
                    <Button className="view-button nodxls-add-btn" onClick={this.handleAddFromAllPages}>
                        {fetching ? <Loading /> : trans.addFromAllPages}
                    </Button>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        fetching: state.items.fetching,
        items: state.items.items
    }
}

export default connect(
    mapStateToProps,
    null
)(CartButtonsPopup);