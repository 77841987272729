import React, {
    Component
} from 'react';
import {
    Modal,
    Button
} from 'react-bootstrap';
import PropTypes from 'prop-types';
import {
    trans
} from '../../utils/translations';

class PopupModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true
        }
    }

    render() {

        const {
            children,
            title,
            handleHideModal,
            classes
        } = this.props

        let className = 'ndoxls-modal'
        if (classes) {
            className += ' ' + classes
        }

        return (
            <div>
            <Modal backdrop="static" bsSize="lg" show={this.state.show} onHide={handleHideModal} className={className} size="lg">
            <Modal.Header closeLabel={trans.close}  onHide={handleHideModal} closeButton><h3>{title}</h3></Modal.Header>
            <Modal.Body>
                {children}
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={handleHideModal}>{trans.close}</Button>
            </Modal.Footer>
            </Modal>
        </div>
        )
    }
}

PopupModal.propTypes = {
    classes: PropTypes.string
}

export default PopupModal;