import {
    MY_TEMPLATES_REQUESTED,
    MY_TEMPLATES_RECEIVED,
    MY_TEMPLATES_FAILED,
    DELETE_MY_TEMPLATE_REQUESTED,
    DELETE_MY_TEMPLATE_RECEIVED,
    DELETE_MY_TEMPLATE_FAILED
} from '../actions/types';
import { removeItem } from '../utils/items';

const initialState = {
    fetching: false,
    items: [],
    itemsCount: 0,
    pages: 1,
    pageSize: 10,
    error: {}
}

export const myTemplates = (state = initialState, action) => {
    switch (action.type) {
        case MY_TEMPLATES_REQUESTED:
            return { ...state,
                fetching: true,
                error: {}
            }
        case MY_TEMPLATES_RECEIVED:
            return { ...state,
                fetching: false,
                error: {},
                items: action.items,
                itemsCount: action.itemsCount,
                pages: Math.ceil(action.itemsCount / action.pageSize),
                pageSize: action.pageSize
            }
        case MY_TEMPLATES_FAILED:
            return { ...state,
                fetching: false,
                error: action.error
            }
        case DELETE_MY_TEMPLATE_REQUESTED:
            return { ...state,
                fetching: true,
                error: {}
            }
        case DELETE_MY_TEMPLATE_RECEIVED:
            return { ...state,
                fetching: false,
                error: {},
                items: removeItem(state.items, action.id)
            }
        case DELETE_MY_TEMPLATE_FAILED:
            return { ...state,
                fetching: false,
                error: action.error
            }
    }

    return state;
}