import {
    bustCache
} from "./cache";

export const currentLang = () => localStorage.getItem('lang');

export const changeLang = (lang) => {
    localStorage.setItem('lang', lang)
    bustCache()
    return lang
}