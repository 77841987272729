
export const insertToArray = (arr, index, newItem) => [
    ...arr.slice(0, index+1),
    newItem,
    ...arr.slice(index+1)
];

export const indexFromArray = (arr, item) => arr.findIndex(e => e.id  === item.id);
export const resultsByType = (arr, type) => arr.filter(a => a['rowType'] === type);
export const biggestIndex = (arr) => Math.max(arr);

export const indexFromArrayByValue = (arr, value, key = "id") => {
    const filtered = arr.filter(a => a[key] === value);
    return filtered.length-1;
}

export const mergeUnique = (arr, newArr) => {
    newArr.map((a) => {
        if (arr.indexOf(a) === -1) {
            arr = [...arr, a]
        }
    });

    return arr;
}

/**
 * Searches array for matching key and replaces it
 * @param {array} arr 
 * @param {object} obj 
 * @param {string} key 
 * @param {string} rKey 
 */
export const arrayReplaceUsingKeys = (arr, obj, key, rKey) => arr.map((o) => {
    if (o[key] == obj[key]) {
        o[rKey] = obj[rKey]
    }
    return o
});

/**
 * Insert into array at index position
 * 
 * @param {array} arr 
 * @param {int} index 
 * @param {object} newItem 
 */
export const insert = (arr, index, newItem) => [
    ...arr.slice(0, index),
    newItem,
    ...arr.slice(index)
];
  