import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import store from './createStore';

import './style.scss';

import Cart from './components/Cart/Cart';
import CartButtons from './components/CartButtons/CartButtons';

const ndoXlsCart = document.getElementById('ndoxls-cart')
if (ndoXlsCart) {
    ReactDOM.render(
        <Provider store={store}>
            <Cart /> 
        </Provider>
    , document.getElementById('ndoxls-cart'))
}

export const renderNdoxlsButtons = () => {
    const ndoXlsButtons = document.getElementById('ndoxls-buttons')
    if (ndoXlsButtons) {
        ReactDOM.render(
            <Provider store={store}>
                <div>
                    <CartButtons />
                    <ReduxToastr
                        timeOut={4000}
                        newestOnTop={false}
                        preventDuplicates
                        position="top-center"
                        transitionIn="fadeIn"
                        transitionOut="fadeOut"
                        progressBar
                        closeOnToastrClick/>
                </div>
            </Provider>
        , document.getElementById('ndoxls-buttons'))
    }
}

module.exports = renderNdoxlsButtons;
window.renderNdoxlsButtons = renderNdoxlsButtons;



