import {
    hashCode
} from "../../../utils/hash";
import { ndoData } from "../../../utils/ndo";


export const checkFromCache = (items, key) => {
    if (key === 'items') {
        items = cleanButtons(items);
    }

    const cached = localStorage.getItem(key);
    const itemsStr = JSON.stringify(items);

    // returns data from cache
    if (cached && JSON.stringify(cached) === itemsStr) {
        console.log('cached ' + key);
        return JSON.parse(cached);
    }

    localStorage.setItem(key, itemsStr)
    console.log('not cached ' + key);
    return items;
}

export const getFromCache = (key) => {
    const cached = localStorage.getItem(key)
    if (cached) {
        console.log('found cached ' + key)
        return JSON.parse(cached)
    }

    return []
}

export const clearCache = (key) => localStorage.removeItem(key);
export const clearCacheAll = () => localStorage.clear();

/**
 * Cleans buttons 
 * We get error if we do not do this
 * @param {array} items 
 */
export const cleanButtons = (items) => {
    return items.map((item) => {
        item.buttons = null
        return item
    })
}

/**
 * Break cache when some check did not pass
 * @return {boolean}
 */
export const cacheBuster = () => {
    const customer = localStorage.getItem('customerId')
    const bustCache = localStorage.getItem('bustCache')

    if (customer != ndoData.customerId) {
        localStorage.setItem('customerId', ndoData.customerId)
        return true
    }
    
    if (bustCache) {
        localStorage.removeItem('bustCache')
        return true
    }

    return false;
}

/**
 * Force cache bust
 */
export const bustCache = () => localStorage.setItem('bustCache', true);