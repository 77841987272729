import React, {
    Component
} from 'react';
import {
    connect
} from 'react-redux';
import {
    Button,
    OverlayTrigger,
} from 'react-bootstrap';
import FontAwesomeIcon from '../FontAwesomeIcon/FontAwesomeIcon';
import {
    toastr
} from 'react-redux-toastr';
import {
    displayTooltip
} from '../XlsTable/utils/items';
import {
    trans
} from '../../utils/translations';
import {
    rebuildColumns
} from '../XlsTable/actions/columns';
import {
    closeModal
} from '../XlsTable/actions/modal';
import {
    replaceHeaderAndFooter, fetchItems
} from '../XlsTable/actions/items';
import {
    deleteMyTemplate
} from './actions/deleteMyTemplate';
import {
    changeLanguage
} from '../XlsTable/actions/languages';
import { bustCache } from '../XlsTable/utils/cache';

class MyTemplatesActions extends Component {

    constructor(props) {
        super(props)

        this.handleUseClick = this.handleUseClick.bind(this)
        this.handleDeleteClick = this.handleDeleteClick.bind(this)
    }

    handleUseClick() {
        const {
            dispatch,
            item
        } = this.props

        bustCache()
        dispatch(changeLanguage(item.lang))
        dispatch(fetchItems())
        dispatch(rebuildColumns(JSON.parse(item.columns)))
        dispatch(replaceHeaderAndFooter(JSON.parse(item.header), JSON.parse(item.footer)))
        dispatch(closeModal())
        toastr.success(trans.successfully, trans.successfullyApplied)
    }

    handleDeleteClick() {
        const {
            dispatch,
            item
        } = this.props

        if (!confirm(trans.areYouSureOnDelete)) {
            return false
        }

        dispatch(deleteMyTemplate(item.id))
        toastr.success(trans.successfully, trans.successfullyRemoved)
    }

    render() {
        return (
            <div>
                <OverlayTrigger placement="top" overlay={displayTooltip(trans.use)}>
                    <Button bsStyle="success"  onClick={this.handleUseClick}><FontAwesomeIcon icon="check"/></Button>
                </OverlayTrigger>
                <OverlayTrigger placement="top" overlay={displayTooltip(trans.delete)}>
                    <Button bsStyle="danger" onClick={this.handleDeleteClick} style={{marginLeft: '5px'}}>
                        <FontAwesomeIcon icon="trash"/>
                    </Button>
                </OverlayTrigger>
            </div>
        )
    }

}

export default connect(
    null,
    null
)(MyTemplatesActions);